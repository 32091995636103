import React from "react";
import { Button, Divider } from "antd-mobile";
import styles from "./index.module.scss";

function InvoiceItem(props: any = {}) {
  const { date, title, amount, status } = props;
  return (
    <div className={styles.app}>
      <div className={styles.content}>
        <ul className={styles.info}>
          <li>{date}&nbsp;&gt;</li>
          <li>抬头名称：{title}</li>
        </ul>
        <div className={styles.amount}>
          <span className={styles.money}>¥</span>
          <span>{amount}</span>
        </div>
      </div>
      <Divider />
      <div className={styles.footer}>
        {status === "done" ? (
          <span>已开票</span>
        ) : (
          <span className={styles.padding}>开票中</span>
        )}
        {status === "done" ? (
          <Button shape="rounded" size="small">
            重新开票
          </Button>
        ) : (
          <Button shape="rounded" size="small" disabled>
            暂无法重发发票
          </Button>
        )}
      </div>
    </div>
  );
}

export default InvoiceItem;
