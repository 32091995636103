import React, { useEffect, useState } from "react";
import { useRequest } from "ahooks";
// import * as dd from "dingtalk-jsapi";
import queryString from "query-string";
// import { Modal } from "antd-mobile";
import { redirect } from "src/common/Utils";
import { loginToken } from "./Services";
import StorageService from "src/common/Utils/store";
import PageLoading from "src/common/Component/PageLoading";

const addParams = (url: string, params: any): string => {
  const index = url.indexOf("?");

  if (index > -1) {
    return `${url}&${queryString.stringify(params)}`;
  }

  return `${url}?${queryString.stringify(params)}`;
};

function Login() {
  const query = queryString.parse(window.location.search);
  const [uri, setUri] = useState("");
  const { run, data: result } = useRequest(loginToken, {
    manual: true,
    onSuccess: (res: any) => {
      const { data = {} } = res || {};
      const { url, isExpires = true } = data || {};

      if (url) {
        const index = url.indexOf("?");
        const path = url.substring(0, index);
        const search = url.substring(index + 1);
        const params: any = queryString.parse(search);

        const newUrl = `${path}?${queryString.stringify({
          ...params,
          redirect_uri: addParams(params?.redirect_uri, query),
        })}`;

        // console.log(111, path, params, addParams(params?.redirect_uri, query));

        setUri(newUrl);

        redirect(newUrl);

        return;
      }

      if (!isExpires) {
        let { route } = query;

        const ELEME_JUMP = 'eleme';
        let route_params = '';
        let parmas = {};

        if (typeof route === 'string' && route.startsWith(ELEME_JUMP)) {
          route_params = route.replace(ELEME_JUMP, '');
          route = ELEME_JUMP;
          parmas = {
            route_params
          };
        }

        const url = route ? `/${route}` : '/';

        redirect(url, parmas);
      }

      if (isExpires) {
        StorageService.shared.removeToken();
        run();
        return;
      }
    },
  });

  useEffect(() => {
    run();
  }, []);

  return (
    <div>
      {/* <div>{uri}</div>
      <div>{JSON.stringify(result)}</div> */}
    </div>
  );

  return <PageLoading />;
}

export default Login;
