import React from "react";
import styles from "./index.module.scss";

function Header() {
  return (
    <div className={styles.app}>
      <span className={styles.label}>开票金额</span>
      <span className={styles.value}>¥28.00</span>
    </div>
  );
}

export default Header;
