import React from "react";
import { STATIC_HOST } from "src/common/Config/Constant";
import Nav from "./Component/Nav";
import styles from "./index.module.scss";

function DailyCoupon() {
  return (
    <div className={styles.app}>
      <div className={styles.banner}></div>
      <Nav />
      <div className={styles.content}>
        <img src={`${STATIC_HOST}/7386662994cd6379.png`} alt="" />
        <img src={`${STATIC_HOST}/db806f59c20addf7.png`} alt="" />
      </div>
    </div>
  );
}

export default DailyCoupon;
