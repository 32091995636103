import React, { useCallback, useState } from "react";
import dayjs from "dayjs";
import { CalendarPicker } from "antd-mobile";
import styles from "./index.module.scss";

function DateRange(props: any) {
  const { onChange, value } = props;
  const [visible, setVisible] = useState(false);
  // const [value, setValue] = useState([]);
  const isEmpty = !value || value.length === 0;

  const onClick = useCallback(() => {
    setVisible(true);
  }, []);

  const onDateChange = useCallback((val: any) => {
    // setValue(val);
    onChange && onChange(val);
  }, []);

  return (
    <div className={styles.app}>
      <div className={styles.input} onClick={onClick}>
        {isEmpty ? (
          <span>自定义开始日期 - 自定义结束日期</span>
        ) : (
          <span>{dayjs(value[0]).format('YYYY-MM-DD')} --- {dayjs(value[1]).format('YYYY-MM-DD')}</span>
        )}
      </div>
      <CalendarPicker
        visible={visible}
        value={value}
        selectionMode="range"
        onChange={onDateChange}
        onClose={() => setVisible(false)}
        onMaskClick={() => setVisible(false)}
      />
    </div>
  );
}

export default DateRange;
