import React from "react";
import { Button } from "antd-mobile";
// import { Link } from "react-router-dom";
import styles from "./index.module.scss";

function Content() {
  return (
    <div className={styles.app}>
      <ul>
        <li className={styles.header}>
          <span>¥</span>
          <span className={styles.amount}>28.00</span>
        </li>
        <li className={styles.list}>
          <span className={styles.label}>下单时间</span>
          <span className={styles.value}>2024-08-26 16:03:24</span>
        </li>
        <li className={styles.list}>
          <span className={styles.label}>支付方式</span>
          <span className={styles.value}>余额支付方式</span>
        </li>
        <li className={styles.list}>
          <span className={styles.label}>订单编号</span>
          <span className={styles.value}>15056742205748</span>
        </li>
        <li className={styles.footer}>
          <a href="/">
            <Button shape="rounded">返回首页</Button>
          </a>
          <Button shape="rounded" color="primary" fill="solid">
            订单详情
          </Button>
        </li>
        <li className={styles.link}>
          <a href="/OrderHistory">查看订单</a>
          {/* <Link to="/orderHistory">查看订单</Link> */}
        </li>
      </ul>
    </div>
  );
}

export default Content;
