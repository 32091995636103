import React, { useState, useCallback, useEffect } from "react";
// import { Link } from "react-router-dom";
import { useRequest } from "ahooks";
import { Divider, Modal, Toast } from "antd-mobile";
import clipboard from "copy-to-clipboard";
import { useSearchParams } from "react-router-dom";
import { EyeOutline, EyeInvisibleOutline } from "antd-mobile-icons";
import PageLoading from "src/common/Component/PageLoading";
import { redirect } from "src/common/Utils";
import { STATIC_HOST } from "src/common/Config/Constant";
import { getCardOrders, deleteBankCard } from "./Services";
import style from "./index.module.scss";

const TOAST_LOADING = {
  duration: 0,
  icon: "loading",
  content: "正在解绑中…",
};

function OneCard() {
  const [params] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const { run, data, loading } = useRequest(getCardOrders, { manual: true });
  const { run: removeCard } = useRequest(deleteBankCard, {
    manual: true,
    onSuccess: (res: any) => {
      const { success = false, msg } = res || {};
      if (success) {
        Toast.clear();
        Toast.show({
          icon: "success",
          content: "解绑成功",
          afterClose: () => {
            // console.log(444, res);
            redirect(`/wallet`);
          },
        });
        return;
      }

      Toast.clear();
      Toast.show({
        icon: "fail",
        content: msg || "解绑失败，请重试!",
      });
    },
    onError: (err: any) => {
      const { msg } = err || {};
      Toast.clear();
      Toast.show({
        icon: "fail",
        content: msg || "解绑失败，请重试!",
      });
    },
  });
  const { cardNumber, wayName, wayIcon, bgStyle } = data || {};

  useEffect(() => {
    run({ cardId: params.get("cardId") });
  }, []);

  const onToggle = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const removeBankCard = useCallback(() => {
    Modal.confirm({
      showCloseButton: true,
      content: "确认解除绑定？",
      onConfirm: () => {
        Toast.show(TOAST_LOADING);
        removeCard({ cardId: params.get("cardId") });
      },
    });
  }, []);

  const onViewCardNum = useCallback(() => {
    Modal.alert({
      showCloseButton: true,
      content: cardNumber,
      confirmText: "复制卡号",
      onConfirm: () => {
        clipboard(cardNumber);
      },
    });
  }, [cardNumber]);

  const onViewAgreement = useCallback(() => {
    Modal.alert({
      content: '协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议',
    });
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className={style.app}>
      <div className={style.container}>
        <ul className={style.header}>
          <li>吉*琴</li>
          <li>欢迎使用芜湖本地生活</li>
        </ul>
        <div
          className={style.content}
          style={{
            backgroundImage: `url('${STATIC_HOST}/589404262186d952.png')`,
          }}
        >
          <div className={style.info}>
            <div className={style.top}>
              <div className={style.balanceName} onClick={onToggle}>
                <span>余额</span>
                {visible ? <EyeOutline /> : <EyeInvisibleOutline />}
              </div>
              {/* <Link to="/orderHistory">查看账单 &gt;</Link> */}
              <a href="/orderHistory">查看账单 &gt;</a>
            </div>
            <div className={style.balance}>
              {visible ? (
                <div>
                  ¥ <span>589.00</span>
                </div>
              ) : (
                <div>
                  <span>*****</span>
                </div>
              )}
            </div>
            <div className={style.footer}>
              <span onClick={onViewAgreement}>查看协议</span>
              <Divider direction="vertical" className={style.divider} />
              <span onClick={onViewCardNum}>查看卡号</span>
              <Divider direction="vertical" className={style.divider} />
              <span onClick={removeBankCard}>解除绑定</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OneCard;
