import React from "react";
import "./index.css";

function Icon(props: any = {}) {
    const { icon, className = "" } = props;
  return (
    <svg className={`icon ${className}`} aria-hidden="true">
      <use xlinkHref={`#${icon}`}></use>
    </svg>
  );
}

export default Icon;
