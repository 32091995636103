import React, { useCallback } from "react";
import { redirect} from  "src/common/Utils";
import {
  FileOutline,
  ClockCircleOutline,
  QuestionCircleOutline,
} from "antd-mobile-icons";
import styles from "./index.module.scss";

function Header() {
  const onGoto = useCallback((path: string) => {
    redirect(path);
  }, []);

  return (
    <div className={styles.app}>
      <div className={styles.node} onClick={() => onGoto('/invoiceTitle')}>
        <FileOutline fontSize={24} />
        <br />
        <span>抬头管理</span>
      </div>
      <div className={`${styles.node} ${styles.divider}`} onClick={() => onGoto('/invoiceRecord')}>
        <ClockCircleOutline fontSize={24} />
        <br />
        <span>开票历史</span>
      </div>
      <div className={styles.node}>
        <QuestionCircleOutline fontSize={24} />
        <br />
        <span>规则说明</span>
      </div>
    </div>
  );
}

export default Header;
