import React from "react";
import { UpOutline, DownOutline } from "antd-mobile-icons";

import style from "./index.module.scss";

function Header(props: any) {
  const { title, total, showToggle = false, onToggle, visible } = props;

  return (
    <div className={style.header}>
      <div>
        <span>{title}</span>
        {total ? <span className={style.num}>({total}张)</span> : null}
      </div>
      {showToggle ? (
        <div className={style.button} onClick={onToggle}>
          {visible ? (
            <span>
              全部收起 <UpOutline />
            </span>
          ) : (
            <span>
              全部展开 <DownOutline />
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default Header;
