import React, { useState, useCallback, useRef } from "react";
import { Button, Dropdown, Selector } from "antd-mobile";
import { DropdownRef } from "antd-mobile/es/components/dropdown";

// import { UpOutline, DownOutline } from "antd-mobile-icons";
import { options1, options2, options3 } from "./mock";
import DateRange from "../DateRange";
import styles from "./index.module.scss";

const DEFAULT_VALUES = {
  date: null,
  status: ["1"],
  source: ["1"],
  type: ["1"],
};

const style = {
  "--color": "#F7F8F9",
  "--border-radius": "8px",
  "--checked-color": "rgba(233, 241, 254, 1)",
  "--checked-text-color	": "#1879FF",
};

function Filter(props: any) {
  const { onSearch } = props;
  const ref = useRef<DropdownRef>(null);
  const [values, setValues] = useState<any>(DEFAULT_VALUES);

  const onConfirm = useCallback(() => {
    ref.current?.close();

    const params: any = {};

    Object.keys(values).forEach((key: string) => {
      if(!values[key]) {
        return;
      }

      if(key === 'date'){
        params[key] = values[key];
        return;
      }

      if(Array.isArray(values[key])){
        params[key] = values[key].join(',');
        return;
      }

      params[key] = values[key];
    });

    onSearch(params);
  }, [values]);

  const onChange = useCallback(
    (key: string, value: any) => {
      values[key] = value;
      setValues({ ...values });
    },
    [values]
  );

  const onReset = useCallback(() => {
    setValues({ status: ["1"], source: ["1"], type: ["1"] });
  }, []);

  return (
    <div className={styles.app}>
      <div>全部订单</div>
      <div>
        <Dropdown ref={ref}>
          <Dropdown.Item key="filter" title="筛选" className={styles.dropdown}>
            <ul className={styles.filter}>
              <li>订单日期</li>
              <li className={styles.input}>
                <DateRange
                  value={values["date"]}
                  onChange={(val: []) => onChange("date", val)}
                />
              </li>
              <li>开票状态</li>
              <li className={styles.input}>
                <Selector
                  style={style}
                  options={options1}
                  showCheckMark={false}
                  value={values["status"]}
                  onChange={(val, extend) => onChange("status", val)}
                />
              </li>
              <li>订单来源 </li>
              <li className={styles.input}>
                <Selector
                  style={style}
                  options={options2}
                  showCheckMark={false}
                  value={values["source"]}
                  onChange={(val, extend) => onChange("source", val)}
                />
              </li>
              <li>支付方式</li>
              <li className={styles.input}>
                <Selector
                  style={style}
                  options={options3}
                  showCheckMark={false}
                  value={values["type"]}
                  onChange={(val, extend) => onChange("type", val)}
                />
              </li>
              <li className={styles.footer}>
                <Button
                  block
                  shape="rounded"
                  size="middle"
                  className={styles.button}
                  onClick={onReset}
                >
                  重置
                </Button>
                <Button
                  block
                  shape="rounded"
                  size="middle"
                  className={styles.button}
                  color="primary"
                  onClick={onConfirm}
                >
                  确定
                </Button>
              </li>
            </ul>
          </Dropdown.Item>
        </Dropdown>
      </div>
    </div>
  );
}

export default Filter;
