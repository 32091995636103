import React from "react";
import { Button, Toast } from "antd-mobile";
import { AddOutline } from "antd-mobile-icons";

import style from "./index.module.scss";

function AddCard(props: any = {}) {
  const { text, type } = props;

  return (
    <div className={style.app}>
      {/* <a href={`/bankCardAdd?type=${type}`}> */}
      <Button block color="primary" fill="none" className={style.button} onClick={() => {
        Toast.show({
          content: '敬请期待'
        })
      }}>
        <AddOutline className={style.icon} /> {text}
      </Button>
      {/* </a> */}
    </div>
  );
}

export default AddCard;
