import React, { useEffect } from "react";
// import { useRequest } from "ahooks";
// import * as dd from "dingtalk-jsapi";
// import queryString from "query-string";
// import { Modal } from "antd-mobile";
// import { redirect } from "src/common/Utils";
// import { loginToken } from "./Services";
import StorageService from "src/common/Utils/store";
import PageLoading from "src/common/Component/PageLoading";


function Logout() {

  useEffect(() => {
    StorageService.shared.removeToken();
    window.location.href = "/login?route=wallet"
  }, []);

  return <PageLoading />;
}

export default Logout;
