import React from "react";
import {
  Form,
  Input,
  Button,
  Dialog,
  TextArea,
  DatePicker,
  Selector,
  Slider,
  Radio,
  Stepper,
  Switch,
} from "antd-mobile";
import styles from "./index.module.scss";

const radioStyle = {
  "--icon-size": "16px",
  "--font-size": "12px",
  "--gap": "4px",
};

function Content() {
  return (
    <div className={styles.app}>
      <Form
        layout="horizontal"
        footer={
          <Button block type="submit" color="primary" size="large">
            提交
          </Button>
        }
      >
        <Form.Item
          name="type"
          label="发票抬头"
          rules={[{ required: true, message: "发票抬头不能为空" }]}
        >
          <Radio.Group>
            <Radio style={radioStyle} value="1">
              企业单位
            </Radio>
            <Radio style={radioStyle} value="2" className={styles.ml20}>
              个人/非企业单位
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="name"
          label="抬头名称"
          rules={[{ required: true, message: "抬头名称不能为空" }]}
        >
          <Input onChange={console.log} placeholder="请填写单位的抬头名称" />
        </Form.Item>
        <Form.Item
          name="number"
          label="公司税号"
          rules={[{ required: true, message: "公司税号不能为空" }]}
        >
          <Input
            onChange={console.log}
            placeholder="请填写“税号”或“社会信用代码”"
          />
        </Form.Item>
        <Form.Item
          name="email"
          label="电子邮箱"
          rules={[{ required: true, message: "电子邮箱不能为空" }]}
        >
          <Input onChange={console.log} placeholder="用于接收电子发票邮件" />
        </Form.Item>
        <Form.Item
          name="bank"
          label="开户银行"
          rules={[{ required: false, message: "开户银行不能为空" }]}
        >
          <Input onChange={console.log} placeholder="请输入开户银行" />
        </Form.Item>
        <Form.Item
          name="bankNo"
          label="银行账号"
          rules={[{ required: false, message: "银行账号不能为空" }]}
        >
          <Input onChange={console.log} placeholder="请输入银行账号" />
        </Form.Item>
        <Form.Item
          name="address"
          label="注册地址"
          rules={[{ required: false, message: "注册地址不能为空" }]}
        >
          <Input onChange={console.log} placeholder="请输入注册地址" />
        </Form.Item>
        <Form.Item
          name="tal"
          label="注册电话"
          rules={[{ required: false, message: "注册电话不能为空" }]}
        >
          <Input onChange={console.log} placeholder="请输入注册电话" />
        </Form.Item>
      </Form>
    </div>
  );
}

export default Content;
