import apiClient from "src/common/Utils/APIClient";



const getDingTalkInfo = () => {
  return apiClient.get("/api/admin/ding").then((res: any) => {
    return res;
  });
};

export { getDingTalkInfo };
