import apiClient from "src/common/Utils/APIClient";
// import { API_HOST } from "src/common/Config/Constant";

const loginToken = async () => {
  const res = await apiClient.get("/api/admin/ding/dd/token", {});

  return res;
};

const getDingTalkConfig = async (params: any) => {
  const { url = "" } = params || {};
  const res = await apiClient.get("/api/admin/ding/config", {
    param: { 
      url 
    }
  });
  const { result = {} } = res || {};
  
  return result;
};

const dingTalkLogin = async (params: any) => {
  const { authCode = "" } = params || {};
  const res = await apiClient.get("/api/admin/ding/login", { 
    param: { 
      authCode 
    } 
  });
  const { result = {} } = res || {};

  return result;
};

export { loginToken, getDingTalkConfig, dingTalkLogin };
