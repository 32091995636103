import React, { useCallback } from "react";
import { Toast } from "antd-mobile";
import { redirect} from  "src/common/Utils";
// import Icon from "../../../../common/Component/Icon";
import style from "./index.module.scss";

// const ToolbarItem: React.FC<any> = (props = {}) => {
//   const { icon, text, iconClassName = "", onClick } = props;

//   return (
//     <ul className={style["toolbar-item"]} onClick={() => onClick(icon)}>
//       <li>
//         <Icon icon={icon} className={iconClassName} />
//       </li>
//       <li className={style.text}>{text}</li>
//     </ul>
//   );
// };

function Toolbar(props: any) {
  const onClick = useCallback((key: any) => {
    if (key === "eleme") {
      redirect("/eleme");
      return;
    }

    if (key === "coupon") {
      redirect("/dailyCoupon");
      return;
    }

    Toast.show({
      content: "功能还未开放，敬请期待！",
    });
  }, []);

  return (
    <div className={style.toolbar}>
      <div className={style.item} onClick={ () => onClick('eleme')}  ></div>
      <div className={style.item} onClick={ () => onClick('feizhu')} ></div>
      <div className={style.item} onClick={ () => onClick('chaoshi')} ></div>
      <div className={style.item} onClick={ () => onClick('gongyi')} ></div>
      <div className={style.item} onClick={ () => onClick('gaode')} ></div>
      <div className={style.item} onClick={ () => onClick('gonghui')} ></div>
      <div className={style.item} onClick={ () => onClick('coupon')} ></div>
      <div className={style.item} onClick={ () => onClick('shenghuojiaofei')} ></div>
      <div className={style.item} onClick={ () => onClick('tuangou')} ></div>
      <div className={style.item} onClick={ () => onClick('more')} ></div>
    </div>
  );

  // return (
  //   <div className={style.toolbar}>
  //     <ToolbarItem icon="icon-elemo" text="饿了么" onClick={onClick} />
  //     <ToolbarItem icon="icon-feizhu" text="飞猪" onClick={onClick} />
  //     <ToolbarItem icon="icon-chaoshi" text="全能超市" onClick={onClick} />
  //     <ToolbarItem
  //       icon="icon-gongyiguanggaozhanbicon"
  //       text="阿里公益"
  //       onClick={onClick}
  //     />
  //     <ToolbarItem
  //       icon="icon-gaodexiaochengxu-yuancai"
  //       text="高德出行"
  //       onClick={onClick}
  //     />
  //     <ToolbarItem icon="icon-gonghui" text="工会服务" onClick={onClick} />
  //     <ToolbarItem icon="icon-kajuan" text="天天领劵" onClick={onClick} />
  //     <ToolbarItem
  //       icon="icon-shenghuojiaofei"
  //       text="生活缴费"
  //       onClick={onClick}
  //     />
  //     <ToolbarItem icon="icon-tuangou" text="超值团购" onClick={onClick} />
  //     <ToolbarItem
  //       icon="icon-gengduo"
  //       iconClassName={style.more}
  //       text="更多"
  //       onClick={onClick}
  //     />
  //   </div>
  // );
}

export default Toolbar;
