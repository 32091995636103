import React from "react";
import { Checkbox, Divider } from "antd-mobile";
import { regFenToYuan } from "src/common/Utils";
import styles from "./index.module.scss";

function Order(props: any) {
  const { feeAmount, body, expiredTime, subject, payAmount, orderAmount } =
    props;
  return (
    <div className={styles.app}>
      <ul>
        <li className={styles.title}>{subject}</li>
        <li className={styles.content}>
          {body} | {`实付${regFenToYuan(orderAmount)}元`}
        </li>
        <li className={styles.date}>{expiredTime}</li>
        <Divider />
        <li className={styles.action}>
          <Checkbox className={styles.check}>商品费</Checkbox>
          <span>¥{regFenToYuan(payAmount || 0)}</span>
        </li>
        <li className={styles.action}>
          <Checkbox className={styles.check}>技术服务费</Checkbox>
          <span>¥{regFenToYuan(feeAmount || 0)}</span>
        </li>
      </ul>
    </div>
  );
}

export default Order;
