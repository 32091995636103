import React from "react";
import { SafeArea } from "antd-mobile";
import { Routes, Route, Outlet } from "react-router-dom";
// import { useMount, useEventListener } from 'ahooks';
import Home from "./pages/Home";
import Pay from "./pages/Pay";
import Eleme from "./pages/Eleme";
import OneCard from "./pages/OneCard";
import PayResult from "./pages/PayResult";
import Wallet from "./pages/Wallet";
import NoMatch from "./pages/NoMatch";
import Coupon from "./pages/Coupon";
import BankCardDetail from "./pages/BankCardDetail";
import OrderHistory from "./pages/OrderHistory";
import InvoiceRecord from "./pages/InvoiceRecord";
import InvoiceTitle from "./pages/InvoiceTitle";
import DailyCoupon from "./pages/DailyCoupon";
import BankCardAdd from "./pages/BankCardAdd";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Redirect from "./pages/Redirect";
import Promotion from "./pages/Promotion";
import FeedDetail from "./pages/FeedDetail";

import style from "./App.module.scss";

function Layout() {
  return (
    <React.Fragment>
      <SafeArea position="top" />

      <Outlet />
      <SafeArea position="bottom" />
    </React.Fragment>
  );
}

function App(props: any) {
  // useMount(() => {
  //   console.log('--useMount--');
  //   changeHtmlSize();
  // });

  // useEventListener('resize', (e) => {
  //   console.log('--窗口大小改变了--', e);
  //   changeHtmlSize();
  // })

  return (
    <div className={style.App}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="coupon" element={<Coupon />} />
          <Route path="eleme" element={<Eleme />} />
          <Route path="pay" element={<Pay />} />
          <Route path="payResult" element={<PayResult />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="oneCard" element={<OneCard />} />
          <Route path="bankCardDetail" element={<BankCardDetail />} />
          <Route path="orderHistory" element={<OrderHistory />} />
          <Route path="invoiceTitle" element={<InvoiceTitle />} />
          <Route path="invoiceRecord" element={<InvoiceRecord />} />
          <Route path="dailyCoupon" element={<DailyCoupon />} />
          <Route path="login" element={<Login />} />
          <Route path="logout" element={<Logout />} />
          <Route path="bankCardAdd" element={<BankCardAdd />} />
          <Route path="feedDetail" element={<FeedDetail />} />
          <Route path="redirect" element={<Redirect />} />
          <Route path="promotion" element={<Promotion />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
