import React, { useEffect, useState } from "react";
import { useRequest } from "ahooks";
import queryString from "query-string";
import { ELEME_HOST } from "src/common/Config/Constant";


import { redirect } from "src/common/Utils";
import { bindEleme } from "./Services";

import styles from "./index.module.scss";
  
function Eleme() {
  const [data, setData] = useState("");
  const { run, data: result } = useRequest(bindEleme, {
    manual: true,
    onSuccess: (res: any) => {
      const { data = {}, success } = res || {};
      if (success === true) {
        redirect(decodeURIComponent(data));
      } else {
        setData('登录失败，请刷新重试！')
      }
    },
  });

  useEffect(() => {
    const query = queryString.parse(window.location.search);
    const elemeUrl = query?.route_params as string;
    
    run({
      elemeUrl: elemeUrl ? ELEME_HOST[elemeUrl] : '',
    });
  }, []);

  return (
    <div className={styles.app}>
      <div>{data}</div>
      </div>
  );

}

export default Eleme;
