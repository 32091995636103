import React, { useCallback, useEffect } from "react";
import { useRequest } from "ahooks";
import { Button, Divider, Modal, Toast } from "antd-mobile";
import { useSearchParams } from "react-router-dom";
import clipboard from "copy-to-clipboard";
// import { STATIC_HOST } from "src/common/Config/Constant";
import { redirect } from "src/common/Utils";
import CustomCard from "./Component/CustomCard";
import PageLoading from "src/common/Component/PageLoading";
import { replaceBankCard } from "src/common/Utils";
import { getCardOrders, deleteBankCard } from "./Services";
import styles from "./index.module.scss";

const TOAST_LOADING = {
  duration: 0,
  icon: "loading",
  content: "正在解绑中…",
};

function BankCardDetail() {
  const [params] = useSearchParams();
  const { run, data, loading } = useRequest(getCardOrders, { manual: true });
  const { run: removeCard } = useRequest(deleteBankCard, {
    manual: true,
    onSuccess: (res: any) => {
      const { success = false, msg } = res || {};
      if (success) {
        Toast.clear();
        Toast.show({
          icon: "success",
          content: "解绑成功",
          afterClose: () => {
            redirect(`/wallet`);
          },
        });
        return;
      }

      Toast.clear();
      Toast.show({
        icon: "fail",
        content: msg || "解绑失败，请重试!",
      });
    },
    onError: (err: any) => {
      const { msg } = err || {};
      Toast.clear();
      Toast.show({
        icon: "fail",
        content: msg || "解绑失败，请重试!",
      });
    },
  });
  const { cardNumber, wayName, wayIcon, wayCd, bgStyle } = data || {};

  useEffect(() => {
    run({ cardId: params.get("cardId") });
  }, []);

  const removeBankCard = useCallback(() => {
    Modal.confirm({
      showCloseButton: true,
      content: "确认解除绑定？",
      onConfirm: () => {
        Toast.show(TOAST_LOADING);
        removeCard({ cardId: params.get("cardId") });
      },
    });
  }, []);

  const onViewCardNum = useCallback(() => {
    Modal.alert({
      showCloseButton: true,
      content: cardNumber,
      confirmText: "复制卡号",
      onConfirm: () => {
        clipboard(cardNumber);
      },
    });
  }, [cardNumber]);

  const onViewAgreement = useCallback(() => {
    Modal.alert({
      content: '协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议协议',
    });
  }, []);

  const onGoto = useCallback(() => {
    redirect("/orderHistory");
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className={styles.app}>
      <div className={styles.card}>
        <CustomCard
          title={wayName}
          wayCd={wayCd}
          cardNumber={replaceBankCard(cardNumber)}
        />
        <div className={styles.buttons}>
          <div className={styles.footer}>
            <span onClick={onViewAgreement}>查看协议</span>
            <Divider direction="vertical" className={styles.divider} />
            <span onClick={onViewCardNum}>查看卡号</span>
            <Divider direction="vertical" className={styles.divider} />
            <span onClick={removeBankCard}>解除绑定</span>
          </div>
        </div>
      </div>
      <Button
        onClick={onGoto}
        block
        size="large"
        shape="rounded"
        color="primary"
        fill="solid"
      >
        查看账单
      </Button>
    </div>
  );
}

export default BankCardDetail;
