import React from "react";
import { useMount } from "ahooks";

import queryString from "query-string";
import StorageService from "src/common/Utils/store";
import { redirect } from "src/common/Utils";
import PageLoading from "src/common/Component/PageLoading";
// import styles from "./index.module.scss";

function Redirect(props: any) {

  useMount(() => {
    const ELEME_JUMP = 'eleme';
    const query: any = queryString.parse(window.location.search);
    let { token, route} = query;
    let route_params = '';
    let parmas = {};

    StorageService.shared.setToken(token);

    if (route.startsWith(ELEME_JUMP)) {
      route_params = route.replace(ELEME_JUMP, '');
      route = ELEME_JUMP;
      parmas = {
        route_params
      };
    }

    const url = !!route ? route: '';

    redirect(`/${url}`, parmas);
  });

  return <PageLoading />;
}

export default Redirect;
