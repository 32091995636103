import React, { useCallback, useState } from "react";
import { Button, Toast } from "antd-mobile";
import { useRequest } from "ahooks";
import { useSearchParams } from "react-router-dom";
import Header from "./Component/Header";
import Content from "./Component/Content";
import PayPwd from "./Component/PayPwd";
import PageLoading from "src/common/Component/PageLoading";
import { getBuyerPay } from "./Services";
import styles from "./index.module.scss";

function Pay() {
  const [params] = useSearchParams();
  const [visible, setVisible] = useState(false);
  const [payInfo, setPayInfo] = useState<any>({});
  const [coupon, setCoupon] = useState([]);

  const { data, loading } = useRequest(
    getBuyerPay({
      payOrderId: params.get("payOrderId"),
    })
  );

  const onChangePayInfo = useCallback((type: any) => {
    setPayInfo(type);
  }, []);

  const onChangeCoupon = useCallback((val: any) => {
    setCoupon(val);
  }, []);

  const onConfirm = useCallback(() => {

    if (!payInfo || !payInfo?.payMethodId) {
      Toast.show({
        content: "请选择支付方式",
      });
      return;
    }
    setVisible(true);
  }, [payInfo]);

  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className={styles.app}>
      <Header data={data} payInfo={payInfo} />
      <Content
        coupon={coupon}
        payInfo={payInfo}
        data={data}
        onChangeCoupon={onChangeCoupon}
        onChangePayInfo={onChangePayInfo}
      />
      <div className={styles.footer}>
        <Button onClick={onConfirm} block color="primary" size="large">
          确认支付
        </Button>
      </div>
      <PayPwd
        onClose={onClose}
        visible={visible}
        data={data}
        params={{
          ...payInfo,
          payOrderId: params.get("payOrderId"),
        }}
      />
    </div>
  );
}

export default Pay;
