import React, { useState } from "react";
import Header from "../Header";
import AddCard from "../AddCard";
import CustomCard from "../CustomCard";
import { replaceBankCard } from "src/common/Utils";
import styles from "./index.module.scss";

function MetroCard(props: any) {
  const { detialRoute = '',data = {} } = props;
  const [visible, setVisible] = useState(false);

  const { methodName = '', total = 0, bankCards = [] } = data || {};
  const [values, setValues] = useState(bankCards);

  // if (!bankCards || bankCards.length === 0) {
  //   return null;
  // }


  const onToggle = () => {
    const visibleTemp = !visible;
    setVisible(visibleTemp);
    setValues(visibleTemp ? bankCards : bankCards.slice(0, 3))
  };
  
  return (
    <div>
      <Header
        title={`我的${methodName}`}
        visible={visible}
        onToggle={onToggle}
        total={bankCards?.total}
        showToggle={total > 1}
      />
      <div className={styles.app}>
        {
          values.map((el: any, index: number) => {
            const { wayName, cardNumber, cardId, wayCd } = el || {};
            const top = index * 60;
            const style = visible ? { position: "static" } : {}

            return (
              <a href={`${detialRoute}?cardId=${cardId}`} key={cardId}>
                <CustomCard
                  title={wayName}
                  wayCd={wayCd}
                  cardNumber={replaceBankCard(cardNumber)}
                  className={styles.card}
                  style={{ ...style, top: `${top}px` }}
                />
              </a>
            );
          })
        }
      </div>
      <AddCard text={`添加${methodName}`} type="onePay" />
    </div>
  );
}

export default MetroCard;
