import Cookies from 'js-cookie'

export const TOKEN_KEY = "_CITY_DING_TOKEN_";

export default class StorageService {
  private constructor() {}
  public static shared = new StorageService();

  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
    Cookies.set(key, value);
  }

  getItem(key: string) {
    return localStorage.getItem(key) || Cookies.get(key);
  }

  setToken(value: string) {
    StorageService.shared.setItem(TOKEN_KEY, value);
    Cookies.set(TOKEN_KEY, value);
  }

  getToken(): string {
    return StorageService.shared.getItem(TOKEN_KEY) || Cookies.get(TOKEN_KEY) || '';
  }

  removeToken() {
    StorageService.shared.removeItem(TOKEN_KEY);
    Cookies.remove(TOKEN_KEY);
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
    Cookies.remove(key);
  }
}
